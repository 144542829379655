import React from 'react';
import Image from 'reusecore/src/elements/Image';
import { Link } from 'gatsby';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import remember_the_milk_1 from '../../../../../../../common/src/assets/image/remember_the_milk_1.png';
import remember_the_milk_2 from '../../../../../../../common/src/assets/image/remember_the_milk_2.png';
import remember_the_milk_3 from '../../../../../../../common/src/assets/image/remember_the_milk_3.png';
import remember_the_milk_4 from '../../../../../../../common/src/assets/image/remember_the_milk_4.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          create printable calendars
        </Link>{' '}
        containing tasks that are defined in Remember The Milk. Here's how:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar</strong> button on the Calendar Data
          Sources step and select the <strong>Remember The Milk </strong>option.
          The <strong>Add Calendar Source - Remember The Milk</strong> window
          will appear.
          <br />
          <Image alt="" src={remember_the_milk_1} style={{ width: 690 }} />
        </li>
        <li>
          Open{' '}
          <a
            href="https://www.rememberthemilk.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.rememberthemilk.com
          </a>{' '}
          in your web browser. Log into your account if needed.
        </li>
        <li>
          Select the task list you'd like to add. To add all tasks, click{' '}
          <strong>All Tasks</strong>.<br />
          <Image alt="" src={remember_the_milk_2} style={{ width: 503 }} />
        </li>
        <li>
          Click the iCalendar button, located in the upper-right corner.
          <br />
          <Image alt="" src={remember_the_milk_3} style={{ width: 690 }} />
        </li>
        <li>
          Copy the <strong>Events address</strong> URL by clicking the{' '}
          <strong>Copy link address</strong> command on the popup menu.
          Depending on your web browser, this command might be named{' '}
          <strong>Copy shortcut</strong> or <strong>Copy Link Location</strong>.
          <br />
          <Image alt="" src={remember_the_milk_4} style={{ width: 573 }} />
        </li>
        <li>
          After copying the link, the address field at the top of the window
          should be automatically filled. If that doesn't happen, right-click
          the address field and select <strong>Paste</strong> from the popup
          menu, or press <strong>Ctrl+V</strong>.
        </li>
        <li>
          The OK button will now be enabled. Click the OK button to add your
          Remember The Milk task list to PrintableCal's list of available
          calendar sources.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Remember-The-Milk"
      commentsId="commentsplus_post_143_489"
      title="Print Calendars with Tasks from Remember The Milk"
      description="PrintableCal can create printable calendars containing tasks that are defined in Remember The Milk."
      keywords="remember the milk, import rtm tasks, print rtm tasks, printable calendar, printable tasks, printable templates, Gantt Chart, Excel calendar, Word calendar"
      content={content}
    />
  );
};

export default Documentation;
